import React, { useEffect, useRef } from 'react';
import OpenSeadragon from 'openseadragon';

const WallhallaViewer: React.FC = () => {
  const viewerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (viewerRef.current) {
      const viewer = OpenSeadragon({
        element: viewerRef.current,
        tileSources: `/wallhalla.dzi`,
        showNavigator: true,
        defaultZoomLevel: 2,
        minZoomLevel: 0.5,
        maxZoomPixelRatio: 10, // Increase this to allow for deeper zooming
        visibilityRatio: 1.0, // Ensures edge visibility but allows full zooming
        zoomInButton: 'null', // Disable default zoom buttons
        zoomOutButton: 'null', // Disable default zoom buttons
        homeButton: 'null', // Disable default home button
      });

      return () => {
        if (viewer && viewer.destroy) viewer.destroy();
      };
    }
  }, []);

  return (
    <div>
        <div className="flex justify-center mb-4">
        <img
            src="/wallhalla-text.png"
            alt='Wallhalla'
            className="w-1/2 mx-auto mt-8"
        />
        </div>
        {/* Main viewer container */}
        <div ref={viewerRef} style={{ width: '100%', height: '80vh' }}></div>
    </div>
  );
};

export default WallhallaViewer;
