import YouTubeVideo from "@/components/youtube/YouTubeVideo";

const AnniversaryPage: React.FC = () => {
    return (
        <div className="relative bg-cover bg-center bg-no-repeat h-screen w-full" style={{ backgroundImage: "url('/wallhalla-blank-wall.png')" }}>
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-50"></div>

            {/* Content */}
            <div className="relative z-10">
                <div className="mb-2 flex justify-center">
                    <img 
                        src="/not-wallhalla-wall.png"
                        alt="Not Wallhalla"
                        className="w-1/2 mx-auto mt-8"
                    />
                </div>
                <div className="mb-2 flex justify-center text-center">
                    <h2
                        className="font-norse text-8xl text-walhalla"
                        style={{
                            WebkitTextStroke: "4px black",
                        }}
                    >
                        BUT YOU DO NEED TO SHOW THIS VIDEO ON STREAM
                    </h2>
                </div>
                <div className="mb-2 flex justify-center text-center">
                    <h2
                        className="font-norse text-8xl text-walhalla"
                        style={{
                            WebkitTextStroke: "4px black",
                        }}
                    >
                        HAPPY 1 YEAR ANNIVERSARY VANDERWOODTV!!
                    </h2>
                </div>
                <div className="md:container md:mx-auto xl:px-72 mb-4">
                    <YouTubeVideo videoId="o3ZA-jyC3sI?si=rMZUDhBeWkdGmpi3" />
                </div>
                <div className="mb-2 flex justify-center text-center">
                    <h2
                        className="font-norse text-5xl text-walhalla"
                        style={{
                            WebkitTextStroke: "2px black",
                        }}
                    >
                        Thank You VANDERWOODTV!!
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default AnniversaryPage;
