const ComingSoonPage: React.FC = () => {
    return (
        <div
            className="relative bg-cover bg-center bg-no-repeat h-screen w-full"
            style={{ backgroundImage: "url('/wallhalla-blank-wall.png')" }}
        >
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-50"></div>

            {/* Centered Content Wrapper */}
            <div className="relative z-10 flex items-center justify-center h-full w-full">
                <div className="text-center">
                    <div className="mb-2">
                        <h2
                            className="font-norse text-9xl text-walhalla"
                            style={{
                                WebkitTextStroke: "4px black",
                            }}
                        >
                            The Vanderheim Village App
                        </h2>
                    </div>
                    <div className="mb-2">
                        <h2
                            className="font-norse text-9xl text-walhalla"
                            style={{
                                WebkitTextStroke: "4px black",
                            }}
                        >
                            Coming Soon
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoonPage;
