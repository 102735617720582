import { Navigate, createBrowserRouter } from "react-router-dom";
import DashboardLayout from "@/layouts/(dashboard)/DashboardLayout";
import DashboardPage from "@/pages/(dashboard)/DashboardPage";
import WallhallaViewer from "@/pages/WallhallaPage";
import AnniversaryPage from "@/pages/AnniversaryPage";
import ComingSoonPage from "@/pages/ComingSoonPage";
import RedirectToExternal from "@/components/RedirectToExternal";

// Detect subdomain
const hostnameParts = window.location.hostname.split('.');
const subdomain = hostnameParts.length > 2 ? hostnameParts[0] : 'main'; // 'main' for vanderheim.com

// Get host and protocol
const host_including_top_level_domain = window.location.host;
const protocol = window.location.protocol;

console.log(`Subdomain: ${subdomain}`);
console.log(`Host including top-level domain: ${host_including_top_level_domain}`);

// Define routes for each subdomain
const routesForMain = [
    {
        path: "/",
        element: <RedirectToExternal url={`${protocol}//village.${host_including_top_level_domain}`} />,
    },
];

const routesForWallhalla = [
    {
        path: "/",
        element: <WallhallaViewer />,
    },
    {
        path: "/wallhalla-viewer",
        element: <AnniversaryPage />,
    },
];

const routesForLonghouse = [
    {
        path: "/",
        element: <RedirectToExternal url={`${protocol}//village.${host_including_top_level_domain}`} />,
    },
];

const routesForVillage = process.env.NODE_ENV === 'production' ? [
    {
        path: "/*",
        element: <ComingSoonPage />,
    },
] : [
    {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
    },
    {
        path: "/dashboard",
        element: (
            <DashboardLayout />
        ),
        children: [
            {
                index: true,
                element: <DashboardPage />,
            },
        ],
    },
];

// Select the routes based on the detected subdomain
let selectedRoutes;

switch (subdomain) {
    case "wallhalla":
        selectedRoutes = routesForWallhalla;
        break;
    case "longhouse":
        selectedRoutes = routesForLonghouse;
        break;
    case "village":
        selectedRoutes = routesForVillage;
        break;
    default:
        selectedRoutes = routesForMain; // Default to main domain routes
}

// Create the router with the selected routes
export const router = createBrowserRouter(selectedRoutes);
