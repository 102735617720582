import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    ApolloLink,
    from,
} from "@apollo/client";

// Environment variable for your API URL
const API_URL = import.meta.env.VITE_VANDERHEIM_API_URL;

// Configure HTTP link to include cookies and headers
const httpLink = new HttpLink({
    uri: API_URL, // Use your environment variable here
    credentials: "include", // Essential for cookies to be sent with requests
});

// Middleware for CSRF token
const csrfLink = new ApolloLink((operation, forward) => {
    const csrfToken = document.cookie
        .split("; ")
        .find((row) => row.startsWith("csrftoken="));
    if (csrfToken) {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                "X-CSRFToken": csrfToken.split("=")[1],
            },
        }));
    }
    return forward(operation);
});

// Initialize Apollo Client
const client = new ApolloClient({
    link: from([csrfLink, httpLink]), // Chain the CSRF token middleware before the HTTP link
    cache: new InMemoryCache(),
});

export default client;
